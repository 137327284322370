'use client';

import { AddIcon, CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Stack,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import cn from 'classnames';
import React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import CloudflareTurnstile from '@/app/_components/cloudflare-turnstile/CloudflareTurnstile';
import { DealersSelectNewCars } from '@/app/_components/modals/enquire-forms/enquire-now-form-new-cars/components/dealers-select/dealers-select';
import Styles from '@/app/_components/modals/enquire-forms/enquire-now-form-new-cars/components/enquire-form/enquire-form.module.scss';
import { LocationButtonNewCars } from '@/app/_components/modals/enquire-forms/enquire-now-form-new-cars/components/location-button/location-button';
import ThankYouMessage from '@/app/_components/modals/enquire-forms/newsletter-signup/newsletter/thank-you-message/thank-you-message';
import { PopiaNotice } from '@/app/_components/popia-notice/popia-notice';
import {
  ERROR_DESCRIPTION,
  ERROR_TITLE,
} from '@/constants/enquiry-forms/enquiry-forms.constants';
import {
  SPECIAL_FOR_SERVICE,
  SPECIAL_FOR_VEHICLE,
} from '@/constants/specials/specials.constants';
import {
  validateFormEmailField,
  validateFormNameFiled,
  validatePhoneNumberField,
} from '@/constants/validation.constants';
import { useDealersInfo } from '@/hooks/dealers/dealers.hook';
import {
  getFloorCodeForSpecials,
  getSpecialSource,
} from '@/services/link.service';
import { getLeadTypeForSpecial } from '@/services/specials/specials.service';
import { SpecialDetails } from '@/services/SpecialsService.service';
import { createLead } from '@/services/UsedCarsService.service';
import { DocumnetPaia_Popia } from '@/types/config/config.types';
import { VehicleType } from '@/types/used-cars/used-cars.types';
import { getUtmData } from '@/utils/getUtmData';

import styles from './PromotionsForm.module.scss';

enum IsUsedVehicleLead {
  yes = '1',
  no = '0',
}

interface PromotionsFormProps {
  specialDetails: SpecialDetails;
  config?: DocumnetPaia_Popia;
  isOpen?: boolean;
  onClose?: () => void;
  showOpenButton?: boolean;
}

function PromotionsForm({
  specialDetails,
  config,
  isOpen = true,
  onClose,
  showOpenButton = true,
}: PromotionsFormProps) {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const [loading, setIsLoading] = React.useState(false);
  const defaultMessage = 'I would like to make an enquiry about ';

  const [isTurnstileLoading, setIsTurnstileLoading] = useState(false);
  const availableDealershipIds = specialDetails?.dealerships?.map(
    (dealership) => dealership.dealerships_id.id,
  );

  const form = useForm({
    mode: 'all',
    defaultValues: {
      user_name: '',
      email: '',
      phone_number: '',
      message: defaultMessage,
      branch_name: null,
      car_model: null,
      'cf-turnstile-response': undefined as undefined | string,
    },
  });
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = form;

  const { dealersInfo } = useDealersInfo();

  const toast = useToast();

  const [isSubmitted, setIsSubmitted] = useState(false);

  function onSubmit(values: any) {
    const utm_data = getUtmData();

    setIsLoading(true);
    const dealer = dealersInfo.find(({ id }) => id === values.branch_name);

    const special = specialDetails;

    const leadType = getLeadTypeForSpecial(specialDetails);

    const specialDealership = special.dealerships.length
      ? special.dealerships.at(0)?.dealerships_id
      : null;

    const dealerName = dealer?.id;

    const leadData = {
      ...values,
      vehicles: null,
      lead_type: leadType,
      dealer_id: dealer?.id || specialDealership?.id,
      dealer_name: dealer?.name || specialDealership?.name,
      dealer_city: dealer?.city.name || specialDealership?.city?.name,
      dealer_province:
        dealer?.city.name || specialDealership?.city?.province.name,
      dealer_country:
        dealer?.city?.country?.name || specialDealership?.city?.country?.name,
      vehicle_brand: special.brand?.name,
      vehicle_range: special.range?.name || '',
      vehicle_model: special.model?.name,
      vehicle_mmcode: special?.mmcode || '',
      vehicle_kms: special?.mileage,
      vehicle_year: special?.year || null,
      vehicle_colour: special?.vehicles?.spec_body_colors?.name,
      vehicle_vin: special?.vehicles?.vin || '',
      vehicle_price: special?.vehicle_price || null,
      vehicle_registration: special?.vehicles?.registration_number || '',
      vehicle_drivetrain: special?.vehicles?.spec_drivetrain?.value || '',
      vehicle_transmission: special?.vehicles?.spec_transmission?.name || '',
      vehicle_fuel: special?.vehicles?.spec_fuel_types?.name || '',
      vehicle_doors: special?.vehicles?.spec_doors.name || '',
      vehicle_image: special?.vehicles?.main_image || '',
      vehicle_images: special?.vehicles?.images || '',
      vehicle_title: special?.title,
      vehicle_body_type: special?.vehicles?.spec_body_type.name || '',
      vehicle_type: special?.vehicle_type,
      dealership_id: dealer?.id || specialDealership?.id,
      dealership_name: dealer?.name || specialDealership?.name,
      contact_firstname: values?.user_name,
      contact_surname: values?.user_name,
      contact_email: values?.email,
      contact_officephone: values?.phone_number,
      contact_cellphone: values?.phone_number,
      comments: values?.message,
      special: 'special',
      dealer_ref:
        dealer?.cms_used_dealer_code ||
        dealer?.cms_new_dealer_code ||
        specialDealership?.cms_used_dealer_code ||
        specialDealership?.cms_new_dealer_code,
      dealer_floor:
        special?.special_for === SPECIAL_FOR_VEHICLE || !special?.special_for
          ? getFloorCodeForSpecials(
              dealer?.cms_used_leads ?? specialDealership?.cms_used_leads,
              dealer?.cms_new_leads ?? specialDealership?.cms_new_leads,
              special?.vehicle_type,
            )
          : special?.special_for === SPECIAL_FOR_SERVICE
            ? dealer?.cms_service_leads
            : dealer?.cms_parts_leads,
      vehicle_used:
        special?.vehicle_type === VehicleType.New
          ? IsUsedVehicleLead.no
          : IsUsedVehicleLead.yes,
      related_specials: special?.id || null,
      source: getSpecialSource(special),
      utm_source: utm_data?.utm_source || '',
      utm_medium: utm_data?.utm_medium || '',
      utm_campaign: utm_data?.utm_campaign || '',
      utm_content: utm_data?.utm_content || '',
      utm_term: utm_data?.utm_term || '',
      vehicle_testdrive_location_at_contact:
        values?.location_at_contact || false,
      vehicle_testdrive: leadType === 'test-drive',
      utm_dealership: utm_data?.utm_dealership || '',
      fbclid: utm_data?.fbclid || '',
      gclid: utm_data?.gclid || '',
      dealership_micro_site: dealerName,
    };

    try {
      createLead(leadData).then((_data) => {
        setIsLoading(false);
        setIsSubmitted(true);
      });
    } catch (err) {
      console.error(err);

      toast({
        title: ERROR_TITLE,
        description: ERROR_DESCRIPTION,
        status: 'error',
      });
      setIsLoading(false);
      return;
    }
  }

  return (
    <Box className={styles.stickyForm}>
      {isDesktop && !isOpen && showOpenButton && (
        <IconButton
          aria-label="Open form"
          icon={<AddIcon />}
          onClick={onClose}
          className={styles.openButton}
          size="sm"
        />
      )}
      {isDesktop && isOpen && (
        <IconButton
          aria-label="Close form"
          icon={<CloseIcon />}
          onClick={onClose}
          className={styles.closeButton}
          size="sm"
        />
      )}
      {(isDesktop && isOpen) || !isDesktop ? (
        <Box gap={2} className={styles.formContainer}>
          {isSubmitted ? (
            <ThankYouMessage onDone={() => setIsSubmitted(false)} />
          ) : (
            <Stack
              as="form"
              onSubmit={handleSubmit(onSubmit)}
              className={cn(Styles.enquireNowForm, Styles.enquireFormWrapper)}
              gap={4}
              name={'PromotionsForm'}
              id={'promotionsForm'}
            >
              <FormControl id="user_name" isInvalid={!!errors.user_name}>
                <Box className={styles.floatingForm}>
                  <Input
                    {...register('user_name', {
                      validate: validateFormNameFiled,
                    })}
                    name="user_name"
                    className={styles.formInput}
                    variant="flushed"
                    placeholder=" "
                  />
                  <FormLabel className={styles.formLabel}>Name*</FormLabel>
                </Box>
                <FormErrorMessage>
                  {errors.user_name && errors.user_name.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl id="email" isInvalid={!!errors.email}>
                <Box className={styles.floatingForm}>
                  <Input
                    {...register('email', {
                      required: 'Please input your Email!',
                      validate: validateFormEmailField,
                    })}
                    name="email"
                    className={styles.formInput}
                    variant="flushed"
                    placeholder=" "
                    type="email"
                  />
                  <FormLabel className={styles.formLabel}>E-mail*</FormLabel>
                </Box>
                <FormErrorMessage>
                  {errors.email && errors.email.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl id="phone_number" isInvalid={!!errors.phone_number}>
                <Box className={styles.floatingForm}>
                  <Input
                    {...register('phone_number', {
                      required: 'Please input your Phone number!',
                      validate: validatePhoneNumberField,
                    })}
                    name="phone_number"
                    className={styles.formInput}
                    variant="flushed"
                    placeholder=" "
                    type="tel"
                  />
                  <FormLabel className={styles.formLabel}>
                    Mobile Number*
                  </FormLabel>
                </Box>
                <FormErrorMessage>
                  {errors.phone_number && errors.phone_number.message}
                </FormErrorMessage>
              </FormControl>
              <LocationButtonNewCars
                availableDealershipIds={availableDealershipIds}
              />
              <FormControl id="branch_name" isInvalid={!!errors.branch_name}>
                <DealersSelectNewCars
                  name="branch_name"
                  register={register}
                  onChange={(val) => setValue('branch_name', val)}
                  required={true}
                  availableDealershipIds={availableDealershipIds}
                />
                <FormErrorMessage>
                  {errors.branch_name && errors.branch_name.message?.toString()}
                </FormErrorMessage>
              </FormControl>
              <Button
                type="submit"
                isDisabled={isTurnstileLoading}
                isLoading={loading}
                loadingText="Submitting"
                data-cy="send-enquiry-button"
                className={styles.btn}
              >
                <span>Submit</span>
              </Button>
              <CloudflareTurnstile
                onSuccess={(token) => {
                  setValue('cf-turnstile-response', token);
                  setIsTurnstileLoading(false);
                }}
                onError={() => setIsTurnstileLoading(false)}
                onLoad={() => setIsTurnstileLoading(true)}
              />
              {config?.popia_notice && (
                <PopiaNotice
                  documentPaiaPopia={{
                    popia_notice: config.popia_notice,
                  }}
                />
              )}
            </Stack>
          )}
        </Box>
      ) : null}
    </Box>
  );
}

export default PromotionsForm;
