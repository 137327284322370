'use client';

import { Box, Container, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import slugify from 'react-slugify';

import Banner from '@/app/_components/banner/Banner';
import Promotions from '@/app/_components/promotions/Promotions';
import { useFloatingCTA, useSetFormItem } from '@/hooks/floating-cta.hook';
import { getLeadTypeForSpecial } from '@/services/specials/specials.service';
import { SpecialDetails } from '@/services/SpecialsService.service';
import { BannerCarousel } from '@/types/banners/banners.types';
import { DocumnetPaia_Popia } from '@/types/config/config.types';
import type { FeaturedItem } from '@/types/home/home.types';
import { NewCarData } from '@/types/new-cars/new-cars.types';
import { Range } from '@/types/request/request.types';
import { VehicleType } from '@/types/used-cars/used-cars.types';

import BannerSection from './BannerSection';
import CTA from './CTA';
import Overview from './Overview';
import PromotionsForm from './PromotionsForm';
import Variants from './Variants';

interface Props {
  promotionsItems: FeaturedItem[];
  specialDetails: SpecialDetails;
  ranges?: Range[];
  isSpecAvailable?: boolean;
  vehicleTitle?: string;
  newCarDetails?: NewCarData;
  banner?: BannerCarousel;
  config?: DocumnetPaia_Popia;
  mustUsePromotionsForm?: boolean;
}

const PromotionsDetails = ({
  promotionsItems,
  specialDetails,
  ranges = [],
  vehicleTitle,
  isSpecAvailable,
  newCarDetails,
  banner,
  config,
  mustUsePromotionsForm = false,
}: Props) => {
  const setFormItem = useSetFormItem();
  const { setSecondaryFloatingButton, setIsCTAShown, setPrimaryButtonAction } =
    useFloatingCTA();
  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: true,
    onClose: () => setShowOpenButton(false),
  });
  const [showOpenButton, setShowOpenButton] = useState(true);

  const [isLargeScreen] = useMediaQuery('(min-width: 1024px)');

  const formContainerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const promotionsRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const bannerRef = useRef<HTMLDivElement>(null);
  const mobileFormRef = useRef<HTMLDivElement>(null);

  const [formStyle, setFormStyle] = useState({
    position: 'absolute' as 'relative' | 'fixed' | 'absolute',
    top: '80px',
    right: '24px',
    opacity: 1,
    transform: 'translateY(0)',
    maxWidth: '340px',
    width: '100%',
    zIndex: 10,
  });

  const handleDesktopFormOpen = () => {
    if (isLargeScreen) {
      onOpen();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      mobileFormRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    setPrimaryButtonAction({
      onClick: handleDesktopFormOpen,
      openForm: mustUsePromotionsForm ? false : true,
    });

    const handleScroll = () => {
      if (isLargeScreen) {
        setIsCTAShown(true);

        if (
          !formContainerRef.current ||
          !contentRef.current ||
          !promotionsRef.current ||
          !wrapperRef.current ||
          !bannerRef.current
        )
          return;

        const promotionsRect = promotionsRef.current.getBoundingClientRect();
        const formRect = formContainerRef.current.getBoundingClientRect();
        const wrapperRect = wrapperRef.current.getBoundingClientRect();

        const headerHeight = 80;
        const stopPosition =
          promotionsRect.top + window.scrollY - formRect.height - headerHeight;

        const wrapperRight = (window.innerWidth - wrapperRect.width) / 2 + 24;

        if (window.scrollY < stopPosition) {
          setFormStyle({
            position: 'fixed',
            top: `${headerHeight}px`,
            right: `${wrapperRight}px`,
            opacity: 1,
            transform: 'translateY(0)',
            maxWidth: '340px',
            width: '100%',
            zIndex: 10,
          });
        } else {
          setFormStyle({
            position: 'absolute',
            top: `${stopPosition - headerHeight}px`,
            right: '24px',
            opacity: 0,
            transform: 'translateY(-20px)',
            maxWidth: '340px',
            width: '100%',
            zIndex: 10,
          });
        }
      } else {
        if (!mobileFormRef.current) return;
        const formRect = mobileFormRef.current.getBoundingClientRect();
        const formBottom = formRect.top;
        const viewportHeight = window.innerHeight;

        if (formBottom < 0) {
          setIsCTAShown(formBottom < viewportHeight);
        } else {
          setIsCTAShown(false);
        }
      }
    };

    window.addEventListener('resize', handleScroll);

    handleScroll();

    return () => {
      window.removeEventListener('resize', handleScroll);
      setPrimaryButtonAction(null);
    };
  }, [isLargeScreen, setIsCTAShown, setPrimaryButtonAction]);

  useEffect(() => {
    const leadType = getLeadTypeForSpecial(specialDetails);

    setFormItem({
      type: 'special',
      special: specialDetails,
      leadType: leadType,
    });

    if (specialDetails?.vehicle_type === VehicleType.New && vehicleTitle) {
      setSecondaryFloatingButton({
        text: `View ${newCarDetails?.main_title}`,
        url: '/new-cars' + '/' + slugify(vehicleTitle),
        display: 'all',
        colors: {
          desktop: {
            background: '#f3c32c',
            text: '#ffffff',
          },
        },
      });
    }

    return () => {
      setFormItem(undefined);
      setSecondaryFloatingButton(null);
    };
  }, [specialDetails, setFormItem, setSecondaryFloatingButton]);

  return (
    <div className="relative">
      {specialDetails ? (
        <>
          <div ref={bannerRef} className="relative">
            {banner?.mobile_image || banner?.video || banner?.desktop_image ? (
              <Banner
                align={banner?.call_to_action_position}
                special={specialDetails}
                banner={banner}
                model={specialDetails?.model?.name}
              />
            ) : (
              <BannerSection
                specialDetails={specialDetails}
                imageKeys={{
                  desktopKey: 'image-1440-680',
                  mobileKey: 'image-430-305',
                }}
              />
            )}
            {mustUsePromotionsForm && isLargeScreen && (
              <Container
                ref={wrapperRef}
                maxW={'1280'}
                width={'100%'}
                className="absolute top-0 left-1/2 transform -translate-x-1/2 h-full"
              >
                <aside
                  ref={formContainerRef}
                  style={{
                    ...formStyle,
                    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                  }}
                  className="flex-shrink-0 p-4 bg-white rounded-lg shadow-lg"
                >
                  {mustUsePromotionsForm ? (
                    <PromotionsForm
                      config={config}
                      specialDetails={specialDetails}
                      isOpen={isOpen}
                      onClose={onClose}
                      showOpenButton={showOpenButton}
                    />
                  ) : null}
                </aside>
              </Container>
            )}
            {!isLargeScreen && mustUsePromotionsForm && (
              <aside ref={mobileFormRef} className="flex-shrink-0 p-4 w-full">
                <PromotionsForm
                  specialDetails={specialDetails}
                  config={config}
                />
              </aside>
            )}
          </div>
          <Container maxW={'1280'} width={'100%'} className="relative px-6">
            <div className="flex flex-col lg:flex-row gap-8 relative">
              <div ref={contentRef} className="flex-1 max-w-[calc(100%-340px)]">
                <Suspense fallback={null}>
                  <Container maxW={'1280'} width={'100%'}>
                    {specialDetails &&
                      specialDetails?.content_blocks?.length && (
                        <Overview specialDetails={specialDetails} />
                      )}
                    <Variants
                      isSpecAvailable={isSpecAvailable}
                      vehicleTitle={vehicleTitle}
                      specialDetails={specialDetails}
                      newCarDetails={newCarDetails}
                    />
                    {specialDetails.call_to_actions &&
                    specialDetails?.call_to_actions?.length > 0 ? (
                      <Box
                        width="100vw"
                        marginLeft="calc(50% - 50vw)"
                        paddingX={0}
                        position="relative"
                      >
                        <CTA call_to_actions={specialDetails.call_to_actions} />
                      </Box>
                    ) : null}
                  </Container>
                </Suspense>
              </div>
            </div>
          </Container>
        </>
      ) : (
        <Container maxW={'1280'} width={'100%'}>
          <Box>Promotion not found</Box>
        </Container>
      )}
      <div ref={promotionsRef}>
        <Suspense fallback={null}>
          <Promotions
            isShowNavigation
            featuredItems={promotionsItems as any[]}
            sectionTitle="Recommended Promotions"
            ranges={ranges}
          />
        </Suspense>
      </div>
    </div>
  );
};

export default PromotionsDetails;
