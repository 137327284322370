'use client';
import { DownloadIcon } from '@chakra-ui/icons';
import { Box, Container, Flex, Heading } from '@chakra-ui/react';

import LinkCustom from '@/app/_components/link/LinkCustom';
import { getDownloadLink } from '@/services/link.service';
import { capitalizeFirstLetter } from '@/services/string-format.service';

import Styles from './Brochure.module.scss';

const Brochure = ({ model, brochure }: { model: string; brochure: string }) => {
  return (
    <Box className={Styles.brochureWrapper}>
      <Container maxW={'1328px'} w={'100%'} px={{ base: 6, lg: 0 }} py={10}>
        <Flex
          direction={{ base: 'column', md: 'row' }}
          alignItems={'center'}
          justifyContent={{ base: 'flex-start', md: 'center' }}
          gap={{ base: 4, md: 8 }}
        >
          <Box className={Styles.headingElement}>
            <Heading
              as="p"
              fontWeight={'bold'}
              className={Styles.titleMd}
              color={'white'}
            >
              Want to explore the Kia {capitalizeFirstLetter(model)} offline?
            </Heading>
          </Box>
          <LinkCustom
            role="link"
            className="btnOutlineWhite"
            moduleClass={Styles.btnCTA}
            href={getDownloadLink(brochure)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <DownloadIcon width={4} height={4} marginRight={2} />
            Brochure
          </LinkCustom>
        </Flex>
      </Container>
    </Box>
  );
};

export default Brochure;
